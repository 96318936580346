<template>
    <div>
        <LoaderDefault v-if="isLoading" />

        <div v-else>
            <DefaultHeader2 :routeBack="'/Tenders'" :titleHeader="'Lista de anexos de la licitación'" />
            <a-row :gutter="24" type="flex">
                <a-col :span="24" class="mb-24">
                    <section class="data-top">
                        <div class="card-top">
                            <p>{{ dataTender.status }}</p>
                        </div>

                        <div class="card-top">
                            <p>{{ dataTender.entity }}</p>
                        </div>

                        <div class="card-top">
                            <p>{{ dataTender.cuantia }}</p>
                        </div>

                        <div class="card-top">
                            <p>{{ dataTender.type_proccess }}</p>
                        </div>
                        <div class="card-top-description">
                            <p>{{ dataTender.typeObject }}</p>
                        </div>
                    </section>


                    <div class="div-table-container">
                        <div class="card-header-table">
                            <div class="bg-gradient-primary">
                                <h5 class="title-table">Listado de licitaciones</h5>
                            </div>
                        </div>

                        <table class="table">
                            <thead class="tr-thead">
                                <tr class="tr-columns-data">
                                    <th class="th-colum-item">Nombre</th>
                                    <th class="th-colum-item">Descripción</th>
                                    <th class="th-colum-item">Tipo documento</th>
                                    <th class="th-colum-item">Fecha</th>
                                </tr>
                                <tr class="tr-columns-actions">
                                    <th class="th-actions">Acciones</th>
                                </tr>
                            </thead>
                            <tbody class="tbody-container">
                                <tr class="tr-body" v-for="(item, index) in listAnexos" :key="index">
                                    <div class="tr-columns-data-body">
                                        <td class="th-colum-item">{{ item.name }}</td>
                                        <td class="th-colum-item"> {{ item.description }} </td>
                                        <td class="th-colum-item"> Alguna cosa va aqui</td>
                                        <td class="th-colum-item"> {{ item.datePublication }} </td>


                                    </div>
                                    <br>
                                    <div class="tr-columns-actions">
                                        <td class="td-container-action td-success" @click="openPdf(item.urlPdf)">
                                            <i class="el-icon-success icon-action"></i>
                                        </td>

                                        <td class="td-container-action td-danger"
                                            @click="showConfirmation(item.name, item, true)">
                                            <i class="el-icon-delete-solid icon-action"></i>
                                        </td>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script>
import DefaultHeader2 from '../../../components/Headers/DefaultHeader2.vue'
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';
import { db, storage } from '../../../../firebaseConfig';

export default {
    components: {
        DefaultHeader2,
        LoaderDefault
    },
    data() {
        return {
            listAnexos: [],
            _idParam: "",
            idNameAnexo: '',
            number_licitacion: "",
            dataTender: {},
            showFormEditAnexo: false,
            isLoading: false,
            dataItemAnexo: {
                name: "",
                description: "",
                datePublication: "",
                urlPdf: ""
            },
        }
    },
    mounted() {
        this._idParam = this.$route.params._id
        this.getInfoTenderAnexos(this._idParam)
    },
    methods: {
        async getInfoTenderAnexos(_idDoc) {
            this.isLoading = true
            await db.collection('tenders')
                .doc(_idDoc)
                .get().then((querySnapshot) => {
                    this.dataTender = querySnapshot.data()
                    this.listAnexos = querySnapshot.data().anexos;
                    this.isLoading = false
                })
        },

        openPdf(urlPdf) { window.open(urlPdf, '_blank'); },

        openModalEditAnexo(nameAnexo, obj) {
            var modal = document.getElementById('myModal');
            modal.style.display = 'block';
            this.idNameAnexo = nameAnexo
            this.dataItemAnexo = obj
        },

        editAnexo() {
            if (this.validateFormSecondForm()) {
                this.isLoading = true;
                db.collection('tenders')
                    .doc(this._idParam)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            const anexos = doc.data().anexos;
                            const indiceObjetUpdate = anexos.findIndex((anexo) => anexo.name === this.idNameAnexo);
                            if (indiceObjetUpdate > -1) {
                                anexos[indiceObjetUpdate] = this.dataItemAnexo;
                                db.collection('tenders')
                                    .doc(this._idParam)
                                    .update({
                                        anexos: anexos
                                    })
                                    .then(() => {
                                        const storageRef = storage.ref();
                                        const filePath = 'pdfsAnexosTender/' + this._idParam + '/' + this.idNameAnexo;
                                        const newFilePath = 'pdfsAnexosTender/' + this._idParam + '/' + this.idNameAnexo + '/' + 'nuevoNombre';
                                        const fileRef = storageRef.child(filePath);
                                        const newFileRef = storageRef.child(newFilePath);
                                        fileRef.getMetadata()
                                            .then((metadata) => {
                                                // Copia los metadatos del archivo original al nuevo archivo
                                                newFileRef.put(fileRef, metadata)
                                                    .then(() => {
                                                        // Elimina el archivo original después de copiarlo
                                                        fileRef.delete().catch((error) => {
                                                        });
                                                    })
                                                    .catch((error) => {
                                                    });
                                            })
                                            .catch((error) => {
                                            });

                                        this.isLoading = false;
                                        this.closeModal();
                                    })
                                    .catch((error) => {
                                        this.isLoading = false;
                                        this.closeModal();
                                    });
                            } else {
                            }
                        } else {
                            this.isLoading = false;
                            this.closeModal();
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false;
                    });
            }
        },



        validateFormSecondForm() {
            const inputs = document.querySelectorAll('.input-anexo');
            const labels = document.querySelectorAll('.label');
            const elementSelectPdf = document.getElementById('name-pdf-selected')

            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].hasAttribute('required') && inputs[i].value.trim() === '') {
                    const label = document.querySelector(`label[for="${inputs[i].id}"]`);
                    label.style.color = 'red';
                    elementSelectPdf.style.color = "red"
                    label.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    return false;
                } else {
                    inputs[i].style.backgroundColor = '';
                    labels[i].style.color = ''
                    elementSelectPdf.style.color = ''
                }
            }
            return true;
        },

        closeModal() {
            var modal = document.getElementById('myModal');
            modal.style.display = 'none';
        },

        showConfirmation(name, obj, firestore) {
            this.$swal
                .fire({
                    title: 'Eliminar anexo',
                    text: '¿Estás seguro de que deseas realizar esta acción? Al aceptar se eliminará direactamente de la base de datos',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#45A825",
                    cancelButtonText: 'Cancelar',
                })
                .then((result) => {
                    if (result.value) {
                        this.deleteAnexoStorage(name, obj, firestore);
                    }
                });
        },

        async deleteAnexoStorage(name, obj, firestore) {
            const storageRef = storage.ref()
            const pdfRef = storageRef.child('pdfsAnexosTender')
                .child(this._idParam)
                .child(name)
            try {
                if (firestore) { await this.removeTenderFirestore(obj) }
                await pdfRef.delete()
            } catch (error) {                
            }
        },

        async removeTenderFirestore(obj) {
            const docRef = db.collection('tenders').doc(this._idParam);
            const doc = await docRef.get();
            const array = doc.get('anexos');
            const index = array.findIndex((item) => item.name === obj.name);
            if (index > -1) {
                array.splice(index, 1);
            }
            await docRef.update({ ['anexos']: array });
            this.getInfoTenderAnexos(this._idParam)
        },

        loadFilePdf() {
            const input = document.getElementById('file-5')
            input.accept = "application/pdf";
            input?.addEventListener('change', (e) => {
                const target = e.target
                if (target.files && target.files.length > 0) {
                    const nameSelected = document.getElementById('name-pdf-selected');
                    nameSelected.innerHTML = '' + target.files[0].name;
                    this.selectedFile = target.files[0]
                }
            });
        }
    },
}
</script>